import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup/store';
import { useAppSelector } from '../setup/hooks';

export interface RioUserProfile {
    sub?: string;
    aud?: string | string[];
    account?: string;
    givenName?: string;
    familyName?: string;
    name?: string;
    locale?: string;
    email?: string;
    tenant?: string;
}

interface LoginState {
    hasUserSessionEverExpired: boolean;
    userProfile: RioUserProfile | null;
    userSessionExpired: boolean;
}

export const initialState: LoginState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userProfileObtained: (state, action: PayloadAction<RioUserProfile>) => {
            state.userProfile = action.payload;
        },
        userSessionExpired: (state) => {
            state.hasUserSessionEverExpired = true;
            state.userSessionExpired = true;
        },
        userSessionRenewed: (state) => {
            state.userSessionExpired = false;
        },
    },
});

export const { userProfileObtained, userSessionExpired, userSessionRenewed } = loginSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUserProfile = (state: RootState) => state.login.userProfile;
export const getUserAccount = (state: RootState) => state.login.userProfile?.account;

export const hasUserSessionEverExpired = (state: RootState) => state.login.hasUserSessionEverExpired;
export const isUserSessionExpired = (state: RootState) => state.login.userSessionExpired;

export const getTenant = (state: RootState) => state.login.userProfile?.tenant;
export const getIsEuTenant = (state: RootState) => state.login.userProfile?.tenant?.startsWith('rio-eu');

export const useIsTestTenant = () => useAppSelector(getTenant)?.endsWith('.test') ?? false;

export default loginSlice.reducer;
