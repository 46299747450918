import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { main } from './configuration';
import { config } from './config';
import { App } from './features/app/components/App';
import { ErrorBoundary } from './features/app/components/ErrorBoundary';
import DefaultRedirect from './features/app/components/DefaultRoute';
import './configuration/setup/datadog';
import { InvalidLogin } from './features/app/components/InvalidLogin';
import { User } from 'oidc-client-ts';
import { store } from './configuration/setup/store';
import handleLoginRedirect from './configuration/login/redirect';

const redirectUsersUsingOldBookmark = () => {
    if (window.location.href.indexOf('/#assets') > -1) {
        window.location.href = window.location.href.replace('/#assets', '/#/assets');
    }
};

function renderApplication(user: User | void | null) {
    redirectUsersUsingOldBookmark();

    const container = document.getElementById('root');
    const root = createRoot(container!);

    let showApp = true;
    try {
        showApp = user ? user.profile.sub.startsWith('prod-rio-users:') : true;
    } catch (e) {
        console.error(e);
    }

    if (showApp) {
        root.render(
            <React.StrictMode>
                <ErrorBoundary>
                    <Provider store={store}>
                        <HashRouter>
                            <Routes>
                                <Route path="*" element={<DefaultRedirect />} />
                                <Route path="/assets" element={<App />} />
                                <Route path="/assets/:id" element={<App />} />
                            </Routes>
                        </HashRouter>
                    </Provider>
                </ErrorBoundary>
            </React.StrictMode>
        );
    } else {
        root.render(<InvalidLogin />);
    }
}

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;
const isCypress = import.meta.env.VITE_CYPRESS_TEST_RUNTIME;

if ((isDev && config.enableMockServer) || isProdPreview) {
    if (isCypress) {
        import('../mocks/browser')
            .then(({ startWorker }) => startWorker({ forCypress: true }))
            .then(() => main(renderApplication));
    } else {
        import('../mocks/browser')
            .then(({ startWorker }) => startWorker({ forCypress: false }))
            .then(() => main(renderApplication));
    }
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd && !isProdPreview) {
    void main(renderApplication);
}
