import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export interface FormattedMessageWithFallbackProps {
    id: string;
    fallbackId: string;
}

/*
react-intl only supports static fallback texts, no fallback ids - here we have an implementation for that.

Drawbacks:
- we cannot suppress the errors thrown by react-intl if the id has no message even though
  there is a fallback (but these are not logged in production)
 */
const FormattedMessageWithFallback = (props: FormattedMessageWithFallbackProps) => {
    const callChildFunctionOrJustReturn = (snippet: React.ReactNode): string | React.ReactNode => snippet;

    return (
        <FormattedMessage id={props.id}>
            {(snippet) => {
                if (Array.isArray(snippet) && snippet[0] === props.id) {
                    // this is true if the message id found no match in our translations
                    return (
                        <FormattedMessage id={props.fallbackId}>
                            {(fallbackSnippet) => {
                                return <>{callChildFunctionOrJustReturn(fallbackSnippet)}</>;
                            }}
                        </FormattedMessage>
                    );
                } else {
                    return <>{callChildFunctionOrJustReturn(snippet)}</>;
                }
            }}
        </FormattedMessage>
    );
};

export default FormattedMessageWithFallback;
