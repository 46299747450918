import { useGetActiveContractsQuery } from '../../../../services/contractsApi';
import React, { ReactNode } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useAllowAssetDeleteWithActiveContractsToggle } from '../../../../../configuration/featureToggle/toggleHooks';
import { useIsTestTenant } from '../../../../../configuration/login/loginSlice';

/**
 * Small wrapper to maintain component in two different dialogs: delete and archive
 */
const Wrapper = ({ deletionMode, children }: { deletionMode: boolean; children: ReactNode }) => {
    return (
        <>
            {deletionMode ? (
                <div className="alert alert-danger display-flex flex-column gap-15 margin-top-10">{children}</div>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

interface ActiveContractsWarningProps {
    assetId: string;
    deletionMode: boolean;
}

export const ActiveContractsWarning = ({ assetId, deletionMode }: ActiveContractsWarningProps) => {
    const { data: activeContracts } = useGetActiveContractsQuery(assetId);
    const allowAssetDeleteWithActiveContracts = useAllowAssetDeleteWithActiveContractsToggle();
    const isTestTenant = useIsTestTenant();

    if (activeContracts?.length === 0) {
        return <></>;
    }

    return (
        <Wrapper deletionMode={deletionMode}>
            <div className="display-flex gap-10">
                <span
                    className={`text-color-${deletionMode ? 'danger' : 'warning'} text-size-h4 rioglyph rioglyph-${
                        deletionMode ? 'error' : 'warning'
                    }-sign`}
                />
                <div>
                    <strong className="text-size-16">
                        {deletionMode && !allowAssetDeleteWithActiveContracts && !isTestTenant ? (
                            <FormattedMessage
                                id={'assets.assets.asset.delete-confirmation-dialog.content.active-contracts.title'}
                            />
                        ) : (
                            <FormattedMessage
                                id={'assets.assets.asset.archive-confirmation-dialog.content.active-contracts.title'}
                            />
                        )}
                    </strong>
                    <div>
                        {deletionMode ? (
                            <FormattedMessage
                                id={'assets.assets.asset.delete-confirmation-dialog.content.active-contracts.hint'}
                            />
                        ) : (
                            <FormattedMessage
                                id={'assets.assets.asset.archive-confirmation-dialog.content.active-contracts.hint'}
                            />
                        )}
                        <div className="panel panel-default panel-body margin-y-10">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td className="padding-0 padding-bottom-5 text-bold">
                                            <FormattedMessage
                                                id={
                                                    'assets.assets.asset.delete-confirmation-dialog.content.active-contracts.service'
                                                }
                                            />
                                        </td>
                                        <td className="padding-0 padding-bottom-5 text-bold">
                                            <FormattedMessage
                                                id={
                                                    'assets.assets.asset.delete-confirmation-dialog.content.active-contracts.end-date'
                                                }
                                            />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activeContracts?.map(({ product_level_name, ends_at }) => (
                                        <tr className="bg-none" key={product_level_name}>
                                            <td className="padding-0 border-none">{product_level_name}</td>
                                            <td className="padding-0 border-none">{moment(ends_at).format('D.M.Y')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {deletionMode && (
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <button
                                    onClick={() =>
                                        window.open(
                                            encodeURI(
                                                `https://contact-form.rio.cloud/contact?opener=delete asset ${assetId}`
                                            ),
                                            '_blank'
                                        )
                                    }
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    <FormattedMessage
                                        id={'assets.assets.asset.delete-confirmation-dialog.button.contact-support'}
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
