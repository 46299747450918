import { MouseEvent, useEffect, useState } from 'react';

import { trace } from '../../../../../configuration/setup/trace';
import { FormStepSelectBrand } from './FormStepSelectBrand';
import { FormStepConnector } from './FormStepConnector';
import { FormStepSelectTelematics } from './FormStepSelectTelematics';
import { DialogFooter } from './DialogFooter';
import { SimpleDialogWrapper } from './SimpleDialogWrapper';
import { useAppSelector } from '../../../../../configuration/setup/hooks';
import { ALL_BRAND_TILES } from './configuration/brandTileConfig';
import { TELEMATICS_TILES } from './configuration/telematicsTileConfig';
import { FormStepSelectAssetType } from './FormStepSelectAssetType';
import { ASSET_TYPE_TILES } from './configuration/assetTypeTileConfig';
import { getAssets, getEasyOnboardingSelectedAssetId } from '../../../reducers/assets/assetsSlice';
import { getIsEuTenant } from '../../../../../configuration/login/loginSlice';
import { AssetTypeTile, BrandTile, TelematicsTile } from './configuration/types';
import { useLocation } from 'react-router-dom';
import { BrandSearchParamType, StepSearchParamType, VehicleTypeSearchParamType } from './types';
import { AssetType } from '../types';

export enum FormStep {
    connector = 'connector',
    selectAssetType = 'selectAssetType',
    selectBrand = 'selectBrand',
    selectTelematics = 'selectTelematics',
}

export enum FormStepNavigation {
    prev = 'prev',
    next = 'next',
}

const initialState = {
    formStep: FormStep.selectAssetType,
    selectedAssetType: undefined,
};
const EasyOnboardingDialog = () => {
    const [formStep, setFormStep] = useState<FormStep>(initialState.formStep);
    const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeTile | undefined>(
        initialState.selectedAssetType
    );
    const [selectedBrand, setSelectedBrand] = useState<BrandTile | undefined>(undefined);
    const [selectedTelematic, setSelectedTelematic] = useState<TelematicsTile | undefined>(undefined);

    const selectedAssetId = useAppSelector(getEasyOnboardingSelectedAssetId);
    const assets = useAppSelector(getAssets);
    const isEuTenant = useAppSelector(getIsEuTenant);

    const showDialog = useAppSelector((state) => state.assets.easyOnboardingDialog.show);

    const location = useLocation();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const assetType = urlSearchParams.get(VehicleTypeSearchParamType);
        const brand = urlSearchParams.get(BrandSearchParamType);
        const step = urlSearchParams.get(StepSearchParamType);

        if (assetType && assetType in AssetType) {
            setSelectedAssetType(ASSET_TYPE_TILES[assetType as AssetType]);
        }

        if (brand) {
            const selectedBrand = ALL_BRAND_TILES.find((brandTile) => brandTile.name === brand);
            setSelectedBrand(selectedBrand);
        }

        if (step && step in FormStep) {
            setFormStep(step as FormStep);
        }
    }, [location]);

    useEffect(() => {
        if (!showDialog && formStep != initialState.formStep) {
            setFormStep(initialState.formStep);
            setSelectedBrand(undefined);
            setSelectedTelematic(undefined);
        }
    }, [showDialog, formStep]);

    useEffect(() => {
        if (selectedAssetId !== undefined) {
            const selectedAsset = assets.find((it) => it.id === selectedAssetId);
            const alreadySelectedBrand = ALL_BRAND_TILES.find(
                (it) => it.displayName.toLowerCase().trim() === selectedAsset?.brand?.toLowerCase()
            );
            trace('selectedAsset\n', selectedAsset);
            if (alreadySelectedBrand) {
                setSelectedBrand(alreadySelectedBrand);
            }
            if (selectedAsset?.type !== undefined) {
                setSelectedAssetType(ASSET_TYPE_TILES[selectedAsset?.type]);
            }
            if (isEuTenant) {
                setFormStep(FormStep.selectTelematics);
            } else {
                // non-eu tenants should not see the brand/telematics selection
                // when adding a telematics device from sidebar
                const alreadySelectedAssetType = selectedAsset?.type
                    ? ASSET_TYPE_TILES[selectedAsset?.type]
                    : undefined;
                setSelectedAssetType(alreadySelectedAssetType);
                if (selectedAsset && selectedAsset.type !== 'trailer') {
                    setFormStep(FormStep.selectTelematics);
                } else {
                    setSelectedTelematic(TELEMATICS_TILES.noTelematics);
                    setFormStep(FormStep.connector);
                }
            }
        }
    }, [assets, selectedAssetId, isEuTenant]);

    useEffect(() => {
        trace(
            '\nformStep',
            formStep,
            '\nselectedAssetType',
            selectedAssetType,
            '\nselectedBrand',
            selectedBrand,
            '\nselectedTelematicsBrand',
            selectedTelematic
        );
    });

    const handleFormStepNavigation = (goto: FormStepNavigation, e?: MouseEvent) => {
        if (goto === FormStepNavigation.prev) {
            if (formStep === FormStep.selectBrand) {
                setFormStep(FormStep.selectAssetType);
                setSelectedAssetType(undefined);
            } else if (formStep === FormStep.selectTelematics) {
                if(isEuTenant) {
                    setFormStep(FormStep.selectBrand);
                    if (selectedBrand !== undefined) {
                        setSelectedBrand(undefined);
                        setSelectedTelematic(undefined);
                    }
                } else {
                    // for tenant != rio.eu jump back to asset type selection
                    setFormStep(FormStep.selectAssetType);
                    setSelectedAssetType(undefined);
                }
            }
        } else if (goto === FormStepNavigation.next) {
            if (isEuTenant) {
                switch (formStep) {
                    case FormStep.selectAssetType:
                        setFormStep(FormStep.selectBrand);
                        break;
                    case FormStep.selectBrand:
                        setFormStep(FormStep.selectTelematics);
                        break;
                    case FormStep.selectTelematics:
                        setFormStep(FormStep.connector);
                        break;
                    case FormStep.connector:
                        break;
                }
            } else {
                // for tenant != rio.eu jump directly to connector dialog
                switch (formStep) {
                    case FormStep.selectAssetType:
                        // for non-eu tenants go directly to connector screen
                        if ((e?.target as HTMLImageElement).alt === 'trailer') {
                            setFormStep(FormStep.connector);
                        } else {
                            setFormStep(FormStep.selectTelematics);
                        }
                        break;
                    case FormStep.selectTelematics:
                        setFormStep(FormStep.connector);
                        break;
                    case FormStep.connector:
                        break;
                }
            }
        }
    };

    const renderDialogBody = () => {
        switch (formStep) {
            case FormStep.selectBrand:
                return (
                    <FormStepSelectBrand
                        selectedAssetType={selectedAssetType}
                        setSelectedBrand={setSelectedBrand}
                        handleFormStepNavigation={handleFormStepNavigation}
                    />
                );
            case FormStep.selectTelematics:
                return (
                    <FormStepSelectTelematics
                        selectedAssetId={selectedAssetId}
                        selectedBrand={selectedBrand}
                        selectedTelematic={selectedTelematic}
                        selectedAssetType={selectedAssetType}
                        setSelectedTelematic={setSelectedTelematic}
                    />
                );
            case FormStep.connector:
                return (
                    <FormStepConnector
                        selectedTelematic={selectedTelematic}
                        selectedAssetType={selectedAssetType}
                        selectedBrand={selectedBrand}
                    />
                );
            default:
            case FormStep.selectAssetType:
                return (
                    <FormStepSelectAssetType
                        setSelectedAssetType={setSelectedAssetType}
                        handleFormStepNavigation={handleFormStepNavigation}
                    />
                );
        }
    };
    const dialogContent = (
        <div>
            {renderDialogBody()}
            <DialogFooter
                formStep={formStep}
                handleFormStepNavigation={handleFormStepNavigation}
                setSelectedTelematics={setSelectedTelematic}
                selectedTelematics={selectedTelematic}
                setSelectedBrand={setSelectedBrand}
                selectedBrand={selectedBrand}
                selectedAssetId={selectedAssetId}
            />
        </div>
    );
    return (
        <SimpleDialogWrapper
            formStep={formStep}
            selectedAssetType={selectedAssetType}
            selectedBrand={selectedBrand}
            selectedTelematics={selectedTelematic}
            dialogContent={dialogContent}
        />
    );
};

export default EasyOnboardingDialog;
