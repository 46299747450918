import packageJson from '../package.json';
import { ConfigState } from './types';
import { LogLevel } from 'configcat-react';

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        tagService: import.meta.env.VITE_TAG_SERVICE,
        assetAdmin: import.meta.env.VITE_ASSET_ADMIN_BASE_URL,
        marketplace: import.meta.env.VITE_MARKETPLACE_URL,
    },
    external: {
        iframe: {
            assetConnector: import.meta.env.VITE_ASSET_CONNECTOR_IFRAME_SRC_TBM3_AND_MOBILE_APP_AND_MAN_TELEMATICS,
            easyOnboarding: import.meta.env.VITE_EASY_ONBOARDING_IFRAME_SRC,
        },
        tagAdministration: import.meta.env.VITE_TAG_ADMINISTRATION,
        driverAdministration: import.meta.env.VITE_DRIVER_ADMINISTRATION,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '98e4ee2d-baf5-4d7a-ba00-53ab0ed6e797',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'marketplace.read',
            'asset-administration.read',
            'asset-administration.write',
            'tags.read',
            'tags.write',
        ],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockFleetId: import.meta.env.VITE_LOGIN_MOCK_FLEET,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
        postLogoutRedirectUri: import.meta.env.VITE_LOGIN_POST_LOGOUT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: 'https://0b1e9b9bb8664b4f9ba102a03be1300d@o117480.ingest.sentry.io/1445673',
    actions: {
        assets: {
            maximumCreateTagRetries: getNumberEnvValue(import.meta.env.VITE_MAXIMUM_CREATE_TAG_RETRIES, 1),
            sleepBetweenCreateTagRetriesMs: getNumberEnvValue(
                import.meta.env.VITE_SLEEP_BETWEEN_CREATE_TAG_RETRIES_MS,
                1
            ),
        },
    },
    configCat: {
        apiKey: import.meta.env.VITE_CONFIG_CAT_API_KEY ?? '',
        logLevel: import.meta.env.VITE_CONFIG_CAT_LOG_LEVEL === 'INFO' ? LogLevel.Info : LogLevel.Off,
        pollingFrequencyInSeconds: 600,
    },
    dataDog: {
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN ?? '',
        accountId: import.meta.env.VITE_ACCOUNT_ID,
        teamId: import.meta.env.VITE_TEAM_ID,
    },
    enableMockServer: import.meta.env.DEV,
};

function getNumberEnvValue(envValue: string | undefined, defaultValue: number): number {
    return typeof envValue === 'string' ? parseInt(envValue) : defaultValue;
}
